import { configureStore } from "@reduxjs/toolkit";

import loginSlice from './login-slice'
import userEnterpriseSlice from "./userenterprise-slice";

const store = configureStore({
    reducer: {
        login: loginSlice.reducer,
        details:userEnterpriseSlice.reducer
       
    },
});

export default store;