import React, { useEffect, useState } from 'react';
import './App.scss';
import AppRoutes from './AppRoutes';
import Navbar from './shared/Navbar';
import Sidebar from './shared/Sidebar';
import API from './services/API';
import Footer from './shared/Footer';
import { USER_ENTERPRISE_URL, USER_MEL } from '../constants';
import { userEnterpriseActions } from 'store/userenterprise-slice';
import { loginActions } from 'store/login-slice';
import { useDispatch,useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

function App() {
  const [isFullPageLayout, setIsFullPageLayout] = useState(false);
  const dispatch = useDispatch()
  const loader =useSelector((state)=>state.details.loader)
  const user =useSelector((state)=>state.details.user)
  const history =useHistory()
  const location =useLocation()

  useEffect(() => {
    async function fetchData() {
      const token = localStorage.getItem('access_token') ? true : false;
      if (token) {
        if (localStorage.getItem('access_token')) {
          const response = await API.get(USER_MEL);
          if (response.status === 200) {
          
             dispatch(userEnterpriseActions.setUvalue(response.data));
             dispatch(userEnterpriseActions.loadFalse())
             
            
          }else if(response.status ===404){
            history.push('/');
          }
        } else {
          history.push('/');
        }
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    onRouteChanged();
  }, [location.pathname]);

  const onRouteChanged = () => {
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = [
      '/login',
      '/',
      '/vehicledash',
      '/newvehicle',
      '/exitvehicle',
      '/user-pages/login-2',
      '/user-pages/register-1',
      '/user-pages/register-2',
      '/user-pages/lockscreen',
      '/error-pages/error-404',
      '/error-pages/error-500'
    ];

    if (fullPageLayoutRoutes.includes(location.pathname)) {
      setIsFullPageLayout(true);
      document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
     
      const mainPanel = document.querySelector('.main-panel');
      mainPanel.style.marginLeft = '0px';
    } else {
      setIsFullPageLayout(false);
      document.querySelector('.page-body-wrapper').classList.remove('full-page-wrapper');
    }
  };

  let navbarComponent = !isFullPageLayout && !loader ? <Navbar /> : '';
  let sidebarComponent = !isFullPageLayout && !loader ? <Sidebar /> : '';
  let footerComponent = !isFullPageLayout && !loader ? <Footer /> : '';

  return (
    <div className="container-scroller">
      {navbarComponent}
      <div className="container-fluid page-body-wrapper">
        {sidebarComponent}
        <div className="main-panel">
          <div className="content-wrapper">
            
             <AppRoutes  user={user}/>
        
          </div>
          {footerComponent}
        </div>
      </div>
    </div>
  );
}


export default App;
